import React from "react";
import FixedContent from "utilities/customScroll/FixedContent";

import "./breadcrumb.scss";

interface Props {
  data: {
    json: string;
  }[];
}

const Breadcrumb: React.FC<Props> = props => {
  const data = JSON.parse(props.data[0].json);

  return (
    <FixedContent>
      <div className="iwc-breadcrumb">
        <div className="iwc-breadcrumb__inner">
          <div className="iwc-breadcrumb__left">
            <div className="iwc-breadcrumb__point"></div>
            <div className="iwc-breadcrumb__pillow">IWC</div>
            <div className="iwc-breadcrumb__pillow">{data.section}</div>
          </div>
          <div className="iwc-breadcrumb__right">
            <div className="iwc-breadcrumb__pillow">{data.info}</div>
          </div>
        </div>
      </div>
    </FixedContent>
  );
};

export default Breadcrumb;
