import Image from "components/image/Image";
import React, { Fragment, useEffect, useRef, useState } from "react";
import AccordionItem from "./AccordionItem";

import "./accordion.scss";
// import AccordionItem from "./AccordionItem";

interface Props {
  data: any[];
}

const Accordion: React.FC<Props> = props => {
  const [indexActive, setIndexActive] = useState<number>(-1);

  const handleClick = (index: number) => {
    setIndexActive(index);
  };

  return (
    <div className="iwc-accordion">
      {props.data.map((item, key) => {
        const data = JSON.parse(item[0].json);
        const images = item[1].items;
        return (
          <AccordionItem
            data={data}
            images={images}
            key={key}
            isOpen={key === indexActive}
            index={key}
            onClick={handleClick}
          />
        );
      })}
    </div>
  );
};

export default Accordion;
