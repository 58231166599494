import React, { createContext, useEffect, useState } from "react";
import ImagesLoaded from "react-images-loaded";

// Global Load Context providing info to components
// on whether images and fonts are loaded
// so they can do what they do accordingly

export const LoadContext = createContext({ images: false, fonts: false, loaded: false });

const LoadProvider = ({ children, onLoaded }) => {
  const [images, setImages] = useState(false);
  const [fonts, setFonts] = useState(false);

  const [loaded, setLoaded] = useState(false);

  // const onImagesLoaded = () => {
  //   setImages(true);
  // };

  const handleHeroPlay = () => {
    console.log('hero play');
    document.documentElement.classList.add('is-iwc-ready');
  }

  useEffect(() => {
    if (!!fonts) {
      onLoaded();
      setLoaded(true);

      const video = document.getElementById('iwc-hero-video');
      if (!!video) {
        video.addEventListener('play', handleHeroPlay);
      } else {
        handleHeroPlay()
      }
    }
  }, [fonts])

  const loadFonts = ({ families }) => {
    if (typeof window === "object") {
      const WebFont = require("webfontloader");
      let counter = 0;
      WebFont.load({
        custom: {
          families: families,
        },
        fontactive: (familyName, fvd) => {
          counter += 1;
          if (counter === families.length) {
            setFonts(true);
          }
        },
      });
    }
  };

  const ctx = { images, fonts, loadFonts, loaded };

  return (
    <LoadContext.Provider value={ctx}>
      {children}
    </LoadContext.Provider>
  );
};

export default LoadProvider;
