import React, { useContext, useEffect, useRef, useState } from "react";
import Logo from "assets/iwc-logo.svg";

import "./intro.scss";
import TextBlock from "components/textBlock/TextBlock";
import Video from "components/video/Video";
import Rotator from "components/rotator/Rotator";
import classNames from "classnames";
import FixedContent from "utilities/customScroll/FixedContent";
import { isMicrosite } from "utilities/story";
import Image from "components/image/Image";
import { customScrollY } from "utilities/customScroll/customScroll";

interface Props {
  dataset: {
    heroVideo: any;
    introHeadline: any;
    introContent1: any;
    introVideo: any;
    introContent2: any;
    introRotator: any;
  };
}

const Intro: React.FC<Props> = props => {
  const [init, setInit] = useState<boolean>(false);
  const [noAutoplay, setNoAutoplay] = useState<boolean>(false);

  const onVideoPlay = () => {
    setInit(true);
    document.documentElement.classList.add('is-iwc-hero-play');
  };

  const handleAutoplayFailed = () => {
    setNoAutoplay(true);
  }

  useEffect(() => {
    const scrollY = customScrollY();
    console.log(scrollY);
    if (scrollY > window.innerHeight) {
      setInit(true);
      document.documentElement.classList.add('is-iwc-hero-play');
    }
  }, [])

  return (
    <section
      className={classNames("iwc-intro", {
        "is-init": init,
        "is-fallback": noAutoplay
      })}
    >
      {/* HERO VIDEO */}
      <div className="iwc-intro-video">
        <Video
          data={props.dataset.heroVideo}
          background
          onPlay={onVideoPlay}
          onAutoplayFailed={handleAutoplayFailed}
          className="iwc-intro-video__video"
          preload
          videoId="iwc-hero-video"
        />
        <Image {...props.dataset.heroVideo[2].items[0]} className="iwc-intro-video__fallback" />
        <div className="iwc-intro-video__darken" />
      </div>

      {/* HERO */}
      <div className="iwc-intro-hero">
        {!isMicrosite() && <div className="iwc-intro-hero__logo">
          <Logo />
        </div>}
        <h1 className="iwc-intro-hero__title">
          The big pilot <span>Roadshow</span>
        </h1>
      </div>
      {/* INTRO-1 */}
      <div className="iwc-intro-block iwc-intro-block--1">
        <h3
          dangerouslySetInnerHTML={{
            __html: props.dataset.introHeadline[0].text,
          }}
        />
        <div className="iwc-intro-block__text">
          <TextBlock {...props.dataset.introContent1[0]} />
        </div>
      </div>
      <Video
        data={props.dataset.introVideo}
        className="iwc-intro-content-video"
        earlyPlay
      />
      <div className="iwc-intro-block  iwc-intro-block--2">
        <div className="iwc-intro-block__text">
          <TextBlock {...props.dataset.introContent2[0]} />
        </div>
      </div>
      {/* OPENING */}
      <div className="iwc-intro-outro">
        <FixedContent>
          <Rotator {...props.dataset.introRotator[0]} />
        </FixedContent>
      </div>
    </section>
  );
};

export default Intro;
