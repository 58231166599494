import classNames from "classnames";
import Image from "components/image/Image";
import React, { useEffect, useRef, useState } from "react";
import * as utils from "utilities/rocani";

import "./timelineItem.scss";

interface Props {
  data: any;
  img: any;
}

const TimelineItem: React.FC<Props> = props => {
  const mainRef = useRef<HTMLDivElement>(null);
  const copyRef = useRef<HTMLDivElement>(null);
  const aFrame = useRef<any>(null);
  const [isIn, setIsIn] = useState<boolean>(false);

  useEffect(() => {
    const render = () => {
      const main = mainRef.current;
      const copy = copyRef.current;
      if (!!main && !!copy) {
        const vpY = utils.getVpY(main);

        if (vpY > 0.4 && vpY < 0.7) {
          setIsIn(true);
        } else {
          setIsIn(false);
        }

        const vpYMiddle = utils.clamp((vpY - 0.5) * 2, -1, 1);
        // copy.style.transform = `translateY(${-20 * vpYMiddle}vh)`;
        copy.style.setProperty('--plx', `${(-20 * vpYMiddle).toFixed(5)}vh`)
      }
      aFrame.current = requestAnimationFrame(render);
    };

    aFrame.current = requestAnimationFrame(render);
    return () => cancelAnimationFrame(aFrame.current);
  }, []);

  return (
    <div
      className={classNames("iwc-timeline-item", { "is-in": isIn })}
      ref={mainRef}
    >
      <div className="iwc-timeline-item-year" dangerouslySetInnerHTML={{__html: props.data.year}} />
      <Image {...props.img} className="iwc-timeline-item__image" />
      <div className="iwc-timeline-item-data" ref={copyRef}>
        <div className="iwc-timeline-item-data__inner">
          <div className="iwc-timeline-item-data__element is-tl-ref-div">
            <div className="iwc-timeline-item-data__container">
              <h4>{props.data.ref}</h4>
            </div>
          </div>
          <div className="iwc-timeline-item-data__element">
            <div className="iwc-timeline-item-data__container">
              <h4><i>{props.data.ref}</i>{props.data.title}</h4>
              <p>{props.data.text}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelineItem;
