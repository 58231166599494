import classNames from "classnames";
import useInViewport from "hooks/useInViewport";
import useVideoSourceSize from "hooks/useVideoSourceSize";
import React, {
  useEffect,
  useRef,
  useState,
  MouseEvent,
  useContext,
} from "react";
import IconPlay from "assets/play.svg";
import isMobile from "ismobilejs";

import "./video.scss";
import { LoadContext } from "providers/LoadProvider";

interface Props {
  className?: string;
  data: any[];
  background?: boolean;
  boxed?: boolean;
  onPlay?: () => void;
  preload?: boolean;
  controls?: boolean;
  disableAutoplay?: boolean;
  earlyPlay?: boolean;
  videoId?: string;
  onAutoplayFailed?: () => void;
}

const Video: React.FC<Props> = props => {
  const [muted, setMuted] = useState<boolean>(!props.disableAutoplay);
  const [playing, setPlaying] = useState<boolean>(true);
  const [firstPlay, setFirstPlay] = useState<boolean>(false);

  const mainRef = useRef<HTMLDivElement>(null);
  const videoPlayerRef = useRef<HTMLVideoElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);
  const inViewport = useInViewport(mainRef, props.earlyPlay && true);

  const aFrame = useRef<number>(0);

  const src = JSON.parse(props.data[0].json);
  const images = props.data[1];
  const previewImage = !!images && images.items[0];

  const videoSourceSize = useVideoSourceSize();

  const { loaded } = useContext(LoadContext);

  // play & pause
  useEffect(() => {
    const videoPlayer = videoPlayerRef.current;
    if (!!videoPlayer) {
      if (!!playing === true) {
        if (videoPlayer.readyState !== 4) {
          videoPlayer.load();
        }
        const promise = videoPlayer.play();
        setFirstPlay(true);
        !!promise &&
          promise
            .then(() => {
              !!props.onPlay && props.onPlay();
            })
            .catch(error => {
              !!props.onPlay && props.onPlay();
              !!props.onAutoplayFailed && props.onAutoplayFailed();
              console.log("error video play", error);
            });
      } else {
        videoPlayer.pause();
      }
    }
  }, [playing]);

  // set autoplay - in viewport
  useEffect(() => {
    if (props.disableAutoplay) {
      if (!inViewport) {
        setPlaying(inViewport);
      }
    } else {
      setPlaying(loaded && inViewport);
    }

    !inViewport && !props.disableAutoplay && setMuted(true);
  }, [inViewport, loaded, props.disableAutoplay]);

  // timeline / controls
  useEffect(() => {
    const render = () => {
      const video = videoPlayerRef.current;
      const timeline = timelineRef.current;
      if (!!video && !!timeline) {
        if (video.duration) {
          const posX = (1 / video.duration) * video.currentTime;
          timeline.style.setProperty("--timeline-x", posX.toFixed(5));
        }
      }
      aFrame.current = requestAnimationFrame(render);
    };

    props.controls &&
      playing &&
      (aFrame.current = requestAnimationFrame(render));
    return () => cancelAnimationFrame(aFrame.current);
  }, [props.controls, playing]);

  const handleVideoClick = () => {
    if (props.controls) {
      setPlaying(!playing);
    }
  };

  const handleTimelineClick = (e: MouseEvent) => {
    const video = videoPlayerRef.current;
    if (!!video) {
      const target: HTMLElement = e.target as HTMLElement;
      const targetBox = target.getBoundingClientRect();

      const newTime =
        (video.duration / targetBox.width) * (e.clientX - targetBox.left);
      video.currentTime = newTime;

      setPlaying(true);
    }
  };

  return (
    <div
      className={classNames("iwc-video", props.className, {
        "iwc-video--background": props.background,
        "is-controls": props.controls,
        "is-playing": playing,
      })}
      ref={mainRef}
    >
      {!!videoSourceSize && (
        <video
          muted={muted}
          playsInline
          loop
          ref={videoPlayerRef}
          poster={!!previewImage ? previewImage.fluid.src : undefined}
          preload={props.preload ? "auto" : "none"}
          onClick={handleVideoClick}
          id={props.videoId || "no-id"}
        >
          <source src={src[videoSourceSize]} />
        </video>
      )}
      {!!props.controls && (
        <>
          <div className="iwc-video-play">
            <IconPlay />
          </div>
          {!!firstPlay && <div
            className="iwc-video-timeline"
            ref={timelineRef}
            onClick={handleTimelineClick}
          />}
        </>
      )}
    </div>
  );
};

export default Video;
