// import Paragraph from "@components/Paragraph";
// import Highlight from "@components/Highlight";
// import Video from "@components/Video";
// import Images from "@components/Images";
// import ImageText from "@components/ImageText";
// import Gallery from "@components/Gallery";
// import Quote from "@components/Quote";
// import CTA from "@components/CTA";
import Intro from "@containers/intro/Intro";
import History from "@containers/history/History";
import Watches from "@containers/watches/Watches";
import Influencers from "@containers/influencers/Influencers";
import Ambassador from "@containers/ambassador/Ambassador";
import ContentCrew from "@containers/contentCrew/ContentCrew";
import Gallery from "@containers/gallery/Gallery";
import Outro from "@containers/outro/Outro";
import Cities from "@containers/cities/Cities";

export const SECTIONS = {
  Intro,
  History,
  Watches,
  Influencers,
  Ambassador,
  ContentCrew,
  Gallery,
  Outro,
  Cities,
};
export const ELEMENTS = {
  // Paragraph,
  // Highlight,
  // Video,
  // Images,
  // ImageText,
  // Gallery,
  // Quote,
  // CTA,
};

export const isMicrosite = () => {
  return process.env.GATSBY_IWC_ENV === "MICROSITE";
}
  
