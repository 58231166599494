import Image from "components/image/Image";
import React, { useRef, MouseEvent, useEffect, useState } from "react";
import Flickity from "react-flickity-component";
import classNames from "classnames";
import * as utils from "utilities/rocani";
import Hammer from "react-hammerjs";

import "./watchesSlider.scss";
import useMobileViewport from "hooks/useMobileViewport";
import useInViewport from "hooks/useInViewport";

// https://whiteleydesigns.com/code/ticker-style-continuous-flickity-slider/

interface Props {
  data: any[];
}

const WatchesSlider: React.FC<Props> = props => {
  const [index, setIndex] = useState<number>(0);
  const [sliderPaused, setSliderPaused] = useState<boolean>(false);
  const mainRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<any | null>(null);
  const sliderLastX = useRef<number>(0);

  const draggerRef = useRef<HTMLDivElement>(null);
  const draggerButtonRef = useRef<HTMLButtonElement>(null);
  const isDragging = useRef<boolean>(false);

  const continousAnimation = useRef<any>(null);

  const isMobileViewport = useMobileViewport();
  const isInViewport = useInViewport(mainRef);

  const flickityOptions = {
    wrapAround: true,
    prevNextButtons: false,
    pageDots: false,
    initialIndex: isMobileViewport ? 0 : 1,
    freeScroll: isMobileViewport ? false : true,
    autoPlay: isMobileViewport ? 5000 : false,
    cellAlign: "center",
  };

  // set index
  useEffect(() => {
    const handleSliderChange = (e: any) => {
      setIndex(e);
    };
    !!sliderRef.current && sliderRef.current.on("change", handleSliderChange);
    return () => {
      !!sliderRef.current &&
        sliderRef.current.off("change", handleSliderChange);
    };
  }, []);

  // set drag scroll bar
  useEffect(() => {
    const handleFlickityScroll = (fX: any, progress: any) => {
      const dragger = draggerRef.current;
      const draggerButton = draggerButtonRef.current;
      if (!!dragger && !!draggerButton) {
        const posX = fX < 0 ? 1 + fX : fX > 1 ? fX - 1 : fX;
        draggerButton.style.transform = `translateX(${
          -draggerButton.clientWidth +
          (dragger.clientWidth + draggerButton.clientWidth) * posX
        }px)`;
      }
    };
    !!sliderRef.current && sliderRef.current.on("scroll", handleFlickityScroll);
    return () => {
      !!sliderRef.current &&
        sliderRef.current.off("scroll", handleFlickityScroll);
    };
  }, []);

  // continous play
  useEffect(() => {
    const render = () => {
      if (!!sliderPaused) {
        return false;
      }

      const slider = sliderRef.current;
      if (!!slider) {
        slider.x = (slider.x - 2) % slider.slideableWidth;
        slider.selectedIndex = slider.dragEndRestingSelect();
        slider.updateSelectedSlide();
        slider.settle(slider.x);
      }
      continousAnimation.current = requestAnimationFrame(render);
    };

    !isMobileViewport &&
      isInViewport &&
      (continousAnimation.current = requestAnimationFrame(render));
    return () => cancelAnimationFrame(continousAnimation.current);
  }, [isMobileViewport, isInViewport, sliderPaused]);

  // dragger pan
  const handleDraggerPan = (e: any) => {
    const dragger = draggerRef.current;
    const draggerButton = draggerButtonRef.current;
    const slider = sliderRef.current;
    if (!!dragger && !!draggerButton && !!slider) {
      const draggerBox = draggerButton.getBoundingClientRect();
      if (
        e.center.x >= draggerBox.left &&
        e.center.x <= draggerBox.left + draggerBox.width &&
        !isDragging.current
      ) {
        isDragging.current = true;
        setSliderPaused(true);
        sliderLastX.current = slider.x;
      }
      if (isDragging.current) {
        slider.x =
          (sliderLastX.current -
            (e.deltaX / (dragger.clientWidth + draggerButton.clientWidth)) *
              slider.slidesWidth) %
          slider.slideableWidth;
        slider.selectedIndex = slider.dragEndRestingSelect();
        slider.updateSelectedSlide();
        slider.settle(slider.x);
      }
    }

    if (e.isFinal) {
      isDragging.current = false;
      setSliderPaused(false);
    }
  };

  // dot click
  const handleDotClick = (e: MouseEvent) => {
    const index = e.currentTarget.getAttribute("data-index") || "0";
    const slider = sliderRef.current;
    !!slider && slider.select(parseInt(index));
  };

  return (
    <div className="iwc-watches-slider" ref={mainRef}>
      <Flickity
        options={flickityOptions}
        flickityRef={ref => (sliderRef.current = ref)}
        className="iwc-watches-slider__gallery"
      >
        {props.data.map((item, key) => {
          const data = JSON.parse(item[0].json);
          const img = item[1].items[0];
          return (
            <div key={key} className="iwc-watches-slider-item">
              <div className="iwc-watches-slider-item__inner">
                <Image {...img} className="iwc-watches-slider-item__image" />
              </div>
              <a
                href={data.link}
                className="iwc-watches-slider-item__link"
                target="_blank"
                rel="noreferrer"
              />
              <div className="iwc-watches-slider-item__text">
                <b>{data.text1}</b>
                <b>{data.text2}</b>
                <span>{data.text3}</span>
              </div>
            </div>
          );
        })}
      </Flickity>

      <div className="iwc-watches-slider-dots">
        {props.data.map((item, key) => {
          return (
            <button
              key={key}
              className={classNames("iwc-watches-slider-dots__item", {
                "is-active": key === index,
              })}
              data-index={key}
              onClick={handleDotClick}
            />
          );
        })}
      </div>

      <div className="iwc-watches-slider-dragger">
        <div className="iwc-watches-slider-dragger__inner" ref={draggerRef}>
          <button
            className="iwc-watches-slider-dragger__button"
            ref={draggerButtonRef}
          >
            Drag to discover
          </button>
        </div>
        <Hammer onPan={handleDraggerPan}>
          <div className="iwc-watches-slider-dragger__area" />
        </Hammer>
      </div>
    </div>
  );
};

export default WatchesSlider;
