import { Helmet } from "react-helmet";

interface Props {}

const MicrositeMeta: React.FC<Props> = props => {
  return (
    <Helmet>
      <title>
        IWC’s Roving Big Pilot Watch Exhibition Finally Goes Digital
      </title>
      <meta name="description" content="After a year-long traveling exhibition, IWC Schaffhausen’s Big Pilot Roadshow has gone digital, allowing you to experience its iconic watch designs from anywhere." />
      <meta
        property="og:title"
        content="IWC’s Roving Big Pilot Watch Exhibition Finally Goes Digital"
      />
      <meta property="og:description" content="After a year-long traveling exhibition, IWC Schaffhausen’s Big Pilot Roadshow has gone digital, allowing you to experience its iconic watch designs from anywhere." />
      <meta data-rh="true" property="og:image" content="/images/IWC-Big-Pilot.jpg" />
    </Helmet>
  );
};

export default MicrositeMeta;
