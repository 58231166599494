export const clamp = (val: number, valMin: number = 0, valMax: number = 1) => {
  let valReturn = val;
  if (valReturn < valMin) {
    valReturn = valMin;
  } else if (valReturn > valMax) {
    valReturn = valMax;
  }
  return valReturn;
}

export const getDataArrayWithKey = (dataset: any, key: string) => {
  const data: any[] = [];
  const timelineDataKey = "historyWatch_";
  Object.keys(dataset).forEach(item => {
    if (item.includes(key)) {
      const index = parseInt(item.replace(key, ''));
      data[index] = dataset[item];
    }
  })
  return data;
}

export const getVpY = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  const vpY = (rect.top - window.innerHeight) / (window.innerHeight + rect.height) * -1;
  return vpY;
}