import usePortal from "hooks/usePortal";
import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import Image from "components/image/Image";
import { InfluencerDataProps } from "components/accordion/AccordionItem";

import "./modalInterview.scss";

interface Props {
  data: InfluencerDataProps;
  image: any;
  onClose: () => void;
}

const ModalInterview: React.FC<Props> = props => {
  const portal = usePortal("iwc-portal");

  useEffect(() => {
    document.body.classList.add("overlay-open");
    return () => {
      document.body.classList.remove("overlay-open");
    };
  }, []);

  return createPortal(
    <div className="iwc-modal-interview">
      <div className="iwc-modal-interview__close" onClick={props.onClose} />
      <div className="iwc-modal-interview__inner">
        <div className="iwc-modal-interview__top">
          <Image {...props.image} className="iwc-modal-interview__image" />
          <div className="iwc-modal-interview__title">
            {props.data.name}
            <br />
            <span>{props.data.job}</span>
          </div>
        </div>
        <div className="iwc-modal-interview__content">
          <p className="iwc-modal-interview__intro">{props.data.text}</p>
          {props.data.interview.map((item, key) => (
            <div key={key}>
              <b>{item.q}</b>
              <p dangerouslySetInnerHTML={{__html: item.a}} />
            </div>
          ))}
        </div>
      </div>
    </div>,
    portal
  );
};

export default ModalInterview;
