import React, { useContext, useEffect } from "react";
import { LoadContext } from "providers/LoadProvider";
import classNames from "classnames";
import "./loader.scss";
import Rotator from "components/rotator/Rotator";
import LoadingIcon from "assets/loading.svg";
import Logo from "assets/iwc-logo.svg";


interface Props {
  isLoaded: boolean;
}

const Loader: React.FC<Props> = props => {
  let { loaded } = useContext(LoadContext);
  // loaded = false;

  return (
    <div className={classNames("iwc-loader", { "is-hidden": loaded })}>
      <div className="iwc-loader__content">
        <div className="iwc-loader__inner">
          <Logo className="iwc-loader__logo" />
          <LoadingIcon className="iwc-loader__loading" />
        </div>
        {/* <Rotator autoRotate /> */}
      </div>
    </div>
  );
};

export default Loader;
