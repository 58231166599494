import React from "react";
import Logo from "assets/iwc-logo.svg";

import "./micrositeHeader.scss";

interface Props {}

const MicrositeHeader: React.FC<Props> = props => {
  return (
    <div className="microsite-header">
      <div className="microsite-header__logo">
        <a href="https://www.iwc.com" target="_blank" rel="noreferrer">
          <Logo />
        </a>
      </div>
    </div>
  );
};

export default MicrositeHeader;
