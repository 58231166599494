import Marquee from "components/marquee/Marquee";
import React, { useRef } from "react";

import "./cities.scss";

interface Props {
  dataset: {
    marquee: any;
  };
}

const Cities: React.FC<Props> = props => {
  const marqueeData = JSON.parse(props.dataset.marquee[0].json);
  const marqueeData2 = JSON.parse(props.dataset.marquee[1].json);

  const renderContent = (dataSet: any) => (
    <div className="iwc-cities-marquee">
      {dataSet.items.map(
        (item: { city: string; date: string }, key: number) => (
          <div key={key} className="iwc-cities-marquee-item">
            {/* <div className="iwc-cities-marquee-item__date">{item.date}</div> */}
            <div className="iwc-cities-marquee-item__city">{item.city}</div>
          </div>
        )
      )}
    </div>
  );

  return (
    <section className="iwc-cities">
      <Marquee speed={marqueeData.speed}>{renderContent(marqueeData)}</Marquee>
      <Marquee speed={marqueeData.speed} reverse>
        {renderContent(marqueeData2)}
      </Marquee>
    </section>
  );
};

export default Cities;
