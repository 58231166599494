import Accordion from "components/accordion/Accordion";
import Breadcrumb from "components/breadcrumb/Breadcrumb";
import React, { useState } from "react";
import * as utils from "utilities/rocani";

import "./influencers.scss";

interface Props {
  dataset: {
    breadcrumb: any;
    influencersHero: any;
  };
}

const Influencers: React.FC<Props> = props => {
  // console.log(props.dataset.influencerHero)
  const influencersData = utils.getDataArrayWithKey(
    props.dataset,
    "influencer_"
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);

  
  return (
    <section className="iwc-influencers iwc-breadcrumb__parent">
      <Breadcrumb data={props.dataset.breadcrumb} />
      <div className="iwc-influencers__inner">
        <h2
          dangerouslySetInnerHTML={{
            __html: props.dataset.influencersHero[0].text,
          }}
          className="iwc-influencers-hero is-small"
        />
      </div>
      <Accordion data={influencersData} />
    </section>
  );
};

export default Influencers;
