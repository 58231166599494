import { RefObject, useEffect, useRef } from "react";
import * as utils from "utilities/rocani";

const useParallaxBackground = (mainRef: RefObject<HTMLElement>, percentageY: number = 25) => {
  const aFrame = useRef<any>(null);

  useEffect(() => {
    const render = () => {
      const main = mainRef.current;
      if (!!main) {
        const rect = main.getBoundingClientRect();
        const vpY =
          (rect.top - window.innerHeight) / (window.innerHeight + rect.height);
        const vpYminMax = utils.clamp((vpY+0.5) * -2, -1, 1);
        main.style.setProperty('transform', `translate3d(0,${vpYminMax * percentageY}%,0)`)
      }
      aFrame.current = requestAnimationFrame(render);
    }

    aFrame.current = requestAnimationFrame(render);
    return () => cancelAnimationFrame(aFrame.current);
  }, []);
}

export default useParallaxBackground;