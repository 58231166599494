import { useEffect, useState } from "react";

const useMobileViewport = (breakpoint: number = 980) => {
  const isBrowser = typeof window !== "undefined";

  const [isMobileVP, setIsMobileVP] = useState<boolean>(
    isBrowser && window.innerWidth <= 740
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobileVP(window.innerWidth < breakpoint);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobileVP;
};

export default useMobileViewport;
