import { useEffect, useState } from "react";

const useVideoSourceSize = () => {
  const isBrowser = typeof window !== "undefined";

  enum VIDEO_SOURCE_SIZES {
    HD = "hd",
    MD = "md",
    SD = "sd",
  }

  const [
    videoSourceSize,
    setVideoSourceSize,
  ] = useState<VIDEO_SOURCE_SIZES | null>(null);

  useEffect(() => {
    const handleResize = () => {
      let size = VIDEO_SOURCE_SIZES.SD;
      if (window.innerWidth >= 980) {
        size = VIDEO_SOURCE_SIZES.MD;
      } else if (window.innerWidth >= 1600) {
        size = VIDEO_SOURCE_SIZES.HD;
      }
      setVideoSourceSize(size);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return videoSourceSize;
};

export default useVideoSourceSize;
