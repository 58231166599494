import Image from "components/image/Image";
import React, { Fragment } from "react";

import "./timeline.scss";
import TimelineItem from "./TimelineItem";

interface Props {
  data: any[];
}

const Timeline: React.FC<Props> = props => {
  return (
    <div className="iwc-timeline">
      {props.data.map((item, key) => {
        const data = JSON.parse(item[0].json);
        const img = item[1].items[0];
        return <TimelineItem data={data} img={img} key={key} />;
      })}
    </div>
  );
};

export default Timeline;
