import classNames from "classnames";
import Image from "components/image/Image";
import ModalInterview from "components/modal/ModalInterview";
import React, { useEffect, useRef, useState } from "react";
import * as utils from "utilities/rocani";

import "./accordionItem.scss";

export interface InfluencerDataProps {
  name: string;
  job: string;
  text: string;
  interview: { q: string; a: string }[];
}

interface Props {
  data: InfluencerDataProps;
  images: any[];
  index: number;
  isOpen: boolean;
  onClick: (index: number) => void;
}

const AccordionItem: React.FC<Props> = props => {
  const [collapseHeight, setCollapseHeight] = useState<number>(0);
  const collapseInnerRef = useRef<HTMLDivElement>(null);
  const [showInterview, setShowInterview] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      const collapseInner = collapseInnerRef.current;
      if (!!collapseInner) {
        setCollapseHeight(
          props.isOpen
            ? Math.ceil(collapseInner.getBoundingClientRect().height)
            : 0
        );
      }
    };
    handleResize();
    //
    document.addEventListener("resize", handleResize);
    return () => document.removeEventListener("resize", handleResize);
  }, [props.isOpen]);

  const handleClick = () => {
    // props.onClick(props.isOpen ? -1 : props.index);
    !props.isOpen && props.onClick(props.index);
  };

  return (
    <div
      className={classNames("iwc-accordion-item", { "is-open": props.isOpen })}
      onClick={handleClick}
    >
      <div className="iwc-accordion-item__inner">
        <div className="iwc-accordion-item__text">
          <h4 className="iwc-accordion-item__title">
            {props.data.name}
            <br />
            <span>{props.data.job}</span>
          </h4>
          <div
            className="iwc-accordion-item__collapse"
            style={{ height: `${collapseHeight}px` }}
          >
            <div
              className="iwc-accordion-item__collapse-inner"
              ref={collapseInnerRef}
            >
              <p>{props.data.text}</p>
              <div
                className="iwc-accordion-item__button"
                onClick={() => setShowInterview(true)}
              >
                Read the Interview
              </div>
            </div>
          </div>
          <div
            className="iwc-accordion-plus"
            onClick={() => props.isOpen ? props.onClick(-1) : props.onClick(props.index)}
          />
        </div>
        <Image
          {...props.images[0]}
          className="iwc-accordion-item__image"
          onClick={() => props.isOpen && setShowInterview(true)}
        />
      </div>
      {/* INTERVIEW */}
      {showInterview && (
        <ModalInterview
          data={props.data}
          image={props.images[0]}
          onClose={() => setShowInterview(false)}
        />
      )}
    </div>
  );
};

export default AccordionItem;
