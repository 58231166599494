import React, { useRef } from "react";
import Image from "components/image/Image";
import useParallaxElement from "hooks/useParallaxElement";
import "./galleryItem.scss";

interface Props {
  data: any[];
  debug?: boolean;
}

const GalleryItem: React.FC<Props> = props => {
  const mainRef = useRef<HTMLDivElement>(null);
  const hlRef = useRef<HTMLHeadingElement>(null);
  const jsonData = JSON.parse(props.data[1].json);

  useParallaxElement(mainRef, jsonData.parallaxY, jsonData.offset || 0.5);
  useParallaxElement(hlRef, -50);

  return (
    <div className="iwc-gallery-item" style={{zIndex: jsonData.z || 0}}>
      <div className="iwc-gallery-item__inner" ref={mainRef}>
        {!!props.data[2] && (
          <h4
            dangerouslySetInnerHTML={{
              __html: props.data[2].text,
            }}
            className="iwc-gallery-item__text"
            ref={hlRef}
          />
        )}
        <Image
          {...props.data[0].items[0]}
          className="iwc-gallery-item__image"
        />
      </div>
    </div>
  );
};

export default GalleryItem;
