import Breadcrumb from "components/breadcrumb/Breadcrumb";
import React from "react";
import Image from "components/image/Image";
import * as utils from "utilities/rocani";
import "./gallery.scss";
import GalleryItem from "./GalleryItem";

interface Props {
  dataset: {
    breadcrumb: any;
    galleryHero: any;
  };
}

const Gallery: React.FC<Props> = props => {
  const galleryData = utils.getDataArrayWithKey(props.dataset, "galleryImage_");

  return (
    <section className="iwc-gallery iwc-breadcrumb__parent">
      <Breadcrumb data={props.dataset.breadcrumb} />
      <div className="iwc-gallery__inner">
        <h2
          dangerouslySetInnerHTML={{
            __html: props.dataset.galleryHero[0].text,
          }}
          className="iwc-gallery-hero is-small"
        />
        {galleryData.map((item, key) => {
          return (
            <GalleryItem data={item} key={key} debug={key === 0} />
          );
        })}
      </div>
    </section>
  );
};

export default Gallery;
