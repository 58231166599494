import { RefObject, useEffect, useRef, useState } from "react";

const useInViewport = (
  mainRef: RefObject<HTMLElement>,
  isLazy: boolean = false
) => {
  const [inViewport, setInViewport] = useState<boolean>(false);
  const aFrame = useRef<any>();

  useEffect(() => {
    const handleScroll = () => {
      const main = mainRef.current;
      if (!!main) {
        const rect = main.getBoundingClientRect();
        const topBorder = isLazy ? window.innerHeight * 2 : window.innerHeight;
        const inViewport = rect.top <= topBorder && rect.top + rect.height >= 0;
        setInViewport(inViewport);
      }
      aFrame.current = requestAnimationFrame(handleScroll);
    };
    // handleScroll();
    aFrame.current = requestAnimationFrame(handleScroll);
    // window.addEventListener('scroll', handleScroll);
    return () => {
      cancelAnimationFrame(aFrame.current);
      //window.removeEventListener('scroll', handleScroll);
    };
  }, [mainRef]);

  return inViewport;
};

export default useInViewport;
