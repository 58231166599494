import React from "react";

import "./micrositeFooter.scss";

interface Props {}

const MicrositeFooter: React.FC<Props> = props => {
  return (
    <div className="microsite-footer">
      <div className="microsite-footer__wrapper">
        <div className="microsite-footer__inner">
          <div className="microsite-footer-legal">
            <a
              href="https://www.iwc.com/de/en/terms-and-legal/imprint.html"
              className="microsite-footer-legal__link"
              rel="noopener noreferrer"
              target="_blank"
            >
              Imprint
            </a>
            <a
              href="https://www.iwc.com/de/en/terms-and-legal/privacy-policy.html"
              className="microsite-footer-legal__link"
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
            <a
              href="https://www.iwc.com/de/en/terms-and-legal/terms-of-use.html"
              className="microsite-footer-legal__link"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms of use
            </a>
          </div>
          {/* <div className="microsite-footer__social">
            <a href="">Facebook</a>
            <a href="">Twitter</a>
            <a href="">Instagram</a>
            <a href="">Youtube</a>
            <a href="">Spotify</a>
            <a href="">Linkedin</a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MicrositeFooter;
