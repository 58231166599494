import Breadcrumb from "components/breadcrumb/Breadcrumb";
import Video from "components/video/Video";
import React, { useEffect, useRef, useState } from "react";
import isMobile from "ismobilejs";

import "./ambassador.scss";

interface Props {
  dataset: {
    breadcrumb: any;
    ambassadorHero: any;
    ambassadorVideo: any;
  };
}

const Ambassador: React.FC<Props> = props => {
  const [videoEmbed, setVideoEmbed] = useState<string>("");

  const renderIframe = () => {
    return (
      <div className="iwc-ambassador__iframe-wrap">
        <div className="iwc-ambassador__iframe-inner">
          <iframe
            src="https://player.vimeo.com/video/673134506?h=c2f544566b"
            width="640"
            height="360"
            frameBorder="0"
            allow="fullscreen; picture-in-picture"
            allowFullScreen
            scrolling="no"
          ></iframe>
        </div>
      </div>
    );
  };

  const renderVideo = () => {
    return (
      <Video data={props.dataset.ambassadorVideo} disableAutoplay controls />
    );
  };

  useEffect(() => {
    const isMobileAny = isMobile().any;
    setVideoEmbed(isMobileAny ? "iframe" : "custom");
  }, []);

  return (
    <section className="iwc-ambassador iwc-breadcrumb__parent">
      <Breadcrumb data={props.dataset.breadcrumb} />
      <div className="iwc-ambassador__inner">
        <div className="iwc-ambassador-hero">
          <h2
            dangerouslySetInnerHTML={{
              __html: props.dataset.ambassadorHero[0].text,
            }}
            className="is-small"
          />
        </div>
        <div className="iwc-ambassador__video-wrap">
          {videoEmbed === "iframe" && renderIframe()}
          {videoEmbed === "custom" && renderVideo()}
        </div>
        {/* {doIframe.current ? renderIframe() : <div>NOOOOOO</div>} */}
        {/* {doIframe.current ? (
          <div className="iwc-ambassador__iframe-wrap">
            <div className="iwc-ambassador__iframe-inner">
              <iframe
                src="https://player.vimeo.com/video/673134506?h=c2f544566b"
                width="640"
                height="360"
                frameBorder="0"
                allow="fullscreen; picture-in-picture"
                allowFullScreen
                scrolling="no"
              ></iframe>
            </div>
          </div>
        ) :  (
          <div className="iwc-ambassador__video-wrap">
            <Video
              data={props.dataset.ambassadorVideo}
              disableAutoplay
              controls
            />
          </div>
        )} */}
      </div>
    </section>
  );
};

export default Ambassador;
