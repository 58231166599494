import { useEffect, useRef, useState } from "react"

import isMobile from "ismobilejs";
import { useLocation } from "@reach/router";

const useWinHeight = () => {
  const [winHeight, setWinHeight] = useState<number>(0);
  const isInit = useRef<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (!isMobile().any || !isInit.current) {
        setWinHeight(window.innerHeight);
        document.documentElement.style.setProperty(
          "--win-height-static",
          `${window.innerHeight}px`
        );
        isInit.current = true;
      }
      document.documentElement.style.setProperty(
        "--win-height",
        `${window.innerHeight}px`
      );
      document.documentElement.classList.add('is-win-height-init')
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [location.pathname]);

  useEffect(() => {
    isInit.current = false;
    window.dispatchEvent(new Event('resize'));
  }, [location.pathname])

  return winHeight;
}

export default useWinHeight;