import Breadcrumb from "components/breadcrumb/Breadcrumb";
import Image from "components/image/Image";
import Timeline from "components/timeline/Timeline";
import useParallaxElement from "hooks/useParallaxElement";
import React, { useEffect, useRef } from "react";
import * as utils from "utilities/rocani";

import "./history.scss";

interface Props {
  dataset: any;
}

const History: React.FC<Props> = props => {
  const aFrame = useRef<any>();
  const mainRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);

  // useParallaxElement(imageRef, -20);

  // get out the history objects
  const timelineData = utils.getDataArrayWithKey(
    props.dataset,
    "historyWatch_"
  );

  // history opening transition
  useEffect(() => {
    const animate = () => {
      const main = mainRef.current;
      if (!!main) {
        const mainBox = main.getBoundingClientRect();
        const top = mainBox.top * -1;
        const factor = top / window.innerHeight;
        //
        const factorY = utils.clamp(factor * 2);
        const factorX = utils.clamp(factor - 0.5) * 2;
        main.style.setProperty(
          "--open-y",
          ((1 - factorY) * 100).toFixed(5) + "%"
        );
        main.style.setProperty(
          "--open-x",
          ((1 - factorX) * 50).toFixed(5) + "%"
        );
      }
      aFrame.current = requestAnimationFrame(animate);
    };

    aFrame.current = requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(aFrame.current);
    };
  }, []);

  return (
    <section className="iwc-history" ref={mainRef}>
      <div className="iwc-history__line" />
      <div className="iwc-history__masked">
        <Breadcrumb data={props.dataset.breadcrumb} />
        {/* HERO */}
        <div className="iwc-history-hero">
          <h2 className="iwc-history-hero__title" dangerouslySetInnerHTML={{__html: props.dataset.historyHero[0].text}} />
          <div className="iwc-history-hero__image" ref={imageRef}>
            <Image
              className="iwc-history-hero__image-img"
              {...props.dataset.historyHero[2].items[0]}
            />
          </div>
          <h3 className="iwc-history-hero__subtitle">
            {props.dataset.historyHero[1].text}
          </h3>
        </div>
        {/* TIMELINE */}
        <Timeline data={timelineData} />
      </div>
    </section>
  );
};

export default History;
