import isMobile from "ismobilejs";

const lerp = (a, b, n) => (1 - n) * a + n * b;
const ease = 0.1;

export default class CustomScroll {
  constructor({ dom }) {
    this.dom = dom;

    this.aFrame = null;
    this.scrollToRender = 0;
    this.wheel = 0;
    this.isLoaded = false;
    this.isDisabled = isMobile().any;
    if (this.isDisabled) {
      document.body.classList.add("custom-scroll-disabled");
      document.body.style.overflow = 'initial';
    }

    if (!window.core) {
      window.core = {
        scrollTop: 0,
        scrollDisabled: this.isDisabled,
      };
    }

    this.addStyles();
    this.start();
    this.addListener();
  }

  setPosition() {
    const scrollTo = this.scrollToRender;
    if (Math.round(scrollTo) !== Math.round(this.current) || scrollTo < 10) {
      this.dom.style.transform = `translate3d(0,-${scrollTo}px,0)`;
      document.documentElement.style.setProperty("--scroll", `${scrollTo}px`);
    }
  }

  handleWheel(e) {
    if (!this.isLoaded || !!document.body.classList.contains('overlay-open')) {
      return false;
    }
    const scrollable = this.dom;
    const _wheel = this.wheel + e.deltaY;
    const _wheelMax = scrollable.clientHeight - window.innerHeight;
    this.wheel = _wheel < 0 ? 0 : _wheel > _wheelMax ? _wheelMax : _wheel;
  }

  //

  addStyles() {
    if (!this.isDisabled) {
      document.getElementsByTagName("body")[0].style.height = "100vh";
      document.getElementsByTagName("body")[0].style.width = "100%";
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    }
  }

  addListener() {
    document.addEventListener("wheel", this.handleWheel.bind(this), false);
  }

  start() {
    const render = () => {
      if (!this.isLoaded || !!document.body.classList.contains('overlay-open')) {
        this.aFrame = window.requestAnimationFrame(render);
        return false;
      }

      if (this.isDisabled) {
        let scrollTo = window.scrollY;
        scrollTo < 0 && (scrollTo = 0);
        window.core.scrollTop = scrollTo;
        document.documentElement.style.setProperty("--scroll", `${scrollTo}px`);
      } else {
        const current = this.wheel;
        //
        let renderCurrent = lerp(this.scrollToRender, current, ease);
        renderCurrent = Number(renderCurrent.toFixed(2));
        renderCurrent < 0 && (renderCurrent = 0);
        //
        this.scrollToRender = renderCurrent;
        window.core.scrollTop = renderCurrent;
        //
        this.setPosition();
      }
      //
      this.aFrame = window.requestAnimationFrame(render);
    };

    this.aFrame = window.requestAnimationFrame(render);
  }

  stop() {
    cancelAnimationFrame(this.aFrame);
  }

  cleanup() {
    this.stop();
  }

  setLoaded() {
    this.isLoaded = true;
  }
}

export const customScrollY = () => {
  if (!!window.core) {
    return window.core.scrollTop;
  }
  return window.scrollY;
};
