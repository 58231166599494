import React, { useRef } from "react";

interface Props {
  json: any;
}

const TextBlock: React.FC<Props> = props => {
  const data: any = JSON.parse(props.json);

  return (
    <>
      {!!data.textblock &&
        data.textblock.map((item: any) => {
          return Object.keys(item).map((itemKey, key) => {
            const Tag = itemKey as keyof JSX.IntrinsicElements;
            return <Tag key={key}>{item[itemKey]}</Tag>;
          });
        })}
    </>
  );
};

export default TextBlock;
