import useInViewport from "hooks/useInViewport";
import { useEffect, useRef } from "react";
import "./marquee.scss";

interface marqueeProps {
  reverse?: boolean;
  speed?: number;
}

const Marquee: React.FC<marqueeProps> = props => {
  const elRef = useRef<HTMLDivElement>(null!);
  const slideRef = useRef<HTMLDivElement>(null!);
  const animationFrame = useRef<number>(0);
  //
  const left = useRef<number>(0);
  const _speed = props.speed || 1;
  const speed = props.reverse ? -_speed : _speed;
  const inViewport = useInViewport(elRef);
  //
  useEffect(() => {
    const render = () => {
      const slide = slideRef.current;
      if (!!slide) {
        const child: HTMLDivElement = slide.childNodes[ 0 ] as HTMLDivElement;
        if (!!child) {
          left.current = (left.current - speed) % child.clientWidth;
          let x = (props.reverse ? -child.clientWidth + left.current : left.current).toFixed(5);
          slide.style.transform = `translate3d(${x}px, 0, 0)`
        }
      }
      animationFrame.current = requestAnimationFrame(render);
    };
    inViewport && (animationFrame.current = requestAnimationFrame(render));
    return () => cancelAnimationFrame(animationFrame.current);
  }, [inViewport]);

  return (
    <div className="marquee" ref={elRef}>
      <div className="marquee__inner" ref={slideRef}>
        <div className="marquee__row">{props.children}</div>
        <div className="marquee__row">{props.children}</div>
      </div>
    </div>
  );
};

export default Marquee;
