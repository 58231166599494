import React from "react";

interface Props {
  className?: string;
  fluid?: {
    src?: string;
  }
  onClick: () => void;
}

const Image: React.FC<Props> = props => {
  if (!props.fluid || !props.fluid.src) {
    return null;
  }

  return <img src={props.fluid.src} className={props.className} onClick={props.onClick} />
}

export default Image;