import React, { useEffect, useRef } from "react";
import { customScrollY } from "./customScroll";
import "./fixedContent.scss";

interface Props {}

const FixedContent: React.FC<Props> = props => {
  const aFrame = useRef<number>(0);
  const mainRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const render = () => {
      const main = mainRef.current;
      if (!!main && !!main.parentElement) {
        if (!!main.parentElement) {
          const mainBox = main.getBoundingClientRect();
          const parentBox = main.parentElement.getBoundingClientRect();
          main.parentElement.style.position = 'relative';
          
          const isOut = parentBox.top + parentBox.height - mainBox.height <= 0;
          const isFixed = parentBox.top <= 0;

          if (isOut) {
            main.classList.add('out');
            main.classList.remove('fixed');
            main.style.marginTop = `${parentBox.height - mainBox.height}px`;
          } else if (isFixed) {
            main.classList.add('fixed');
            main.classList.remove('out');
            main.setAttribute('style', '');
          } else {
            main.classList.remove('fixed');
            main.classList.remove('out');
            main.setAttribute('style', '');
          }
        }
      }

      aFrame.current = requestAnimationFrame(render);
    }

    aFrame.current = requestAnimationFrame(render);
    return () => cancelAnimationFrame(aFrame.current);
  }, []);

  return <div className="fixed-content" ref={mainRef}>{props.children}</div>;
};

export default FixedContent;
