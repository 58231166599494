import React, { useEffect, useRef, useState } from "react";
import "./layout.scss";
import GlobalFonts from "components/global/GlobalFonts";
import LoadProvider from "providers/LoadProvider";
import CustomScroll from "utilities/customScroll/customScroll";
import Loader from "components/loader/Loader";
import useWinHeight from "hooks/useWinHeight";
import { isMicrosite } from "utilities/story";
import MicrositeHeader from "components/microsite/MicrositeHeader";
import MicrositeFooter from "components/microsite/MicrositeFooter";
import MicrositeMeta from "components/microsite/MicrositeMeta";
import Credits from "components/credits/Credits";
import classNames from "classnames";

interface Props {}

declare global {
  interface Window {
    core: {
      scrollTop: number;
      scrollDisabled: boolean;
    };
  }
}

const Layout: React.FC<Props> = props => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const customScrollRef = useRef<any>(null);
  const mainRef = useRef<HTMLDivElement>(null);

  useWinHeight();

  useEffect(() => {
    const main = mainRef.current;
    if (!!main) {
      customScrollRef.current = new CustomScroll({
        dom: main,
      });
      loaded && customScrollRef.current.setLoaded();
    }
    window.scrollTo(0,0);
    return () => {
      !!customScrollRef.current && customScrollRef.current.cleanup();
    };
    
  }, []);

  useEffect(() => {
    customScrollRef.current && customScrollRef.current.setLoaded();
  }, [loaded]);

  return (
    <>
      {isMicrosite() && <MicrositeMeta />}
      <LoadProvider onLoaded={() => setLoaded(true)}>
        <Loader isLoaded={loaded} />
        <GlobalFonts />
        <main ref={mainRef} className={classNames({"is-microsite": isMicrosite()})}>
          {isMicrosite() && <MicrositeHeader />}
          {props.children} 
          {!isMicrosite() && <Credits/>}
          {isMicrosite() && <MicrositeFooter />}
         
        </main>
      </LoadProvider>
    </>
  );
};

export default Layout;
