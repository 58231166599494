import Breadcrumb from "components/breadcrumb/Breadcrumb";
import Video from "components/video/Video";
import React from "react";

import "./contentCrew.scss";

interface Props {
  dataset: {
    breadcrumb: any;
    contentCrewVideo: any;
  }
}

const ContentCrew: React.FC<Props> = props => {
  return <section className="iwc-content-crew iwc-breadcrumb__parent">
    <Breadcrumb data={props.dataset.breadcrumb} />
    <Video data={props.dataset.contentCrewVideo} earlyPlay />
  </section>
}

export default ContentCrew;