import Image from "components/image/Image";
import Rotator from "components/rotator/Rotator";
import useParallaxBackground from "hooks/useParallaxBackground";
import React, { useRef } from "react";

import "./outro.scss";

interface Props {
  dataset: {
    outroRotator: any;
    outroBgImage: any;
  };
}

const Outro: React.FC<Props> = props => {
  const bgImageRef = useRef<HTMLDivElement>(null);
  useParallaxBackground(bgImageRef, 50);

  return (
    <section className="iwc-outro">
      <div className="iwc-outro-bg" ref={bgImageRef}>
        <Image
          {...props.dataset.outroBgImage[0].items[0]}
          className="iwc-outro-bg__image"
        />
      </div>
      <Rotator {...props.dataset.outroRotator[0]} />
    </section>
  );
};

export default Outro;
