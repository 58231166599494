import { RefObject, useEffect, useRef } from "react";
import * as utils from "utilities/rocani";

const useParallaxElement = (mainRef: RefObject<HTMLElement>, percentageY: number = 25, offsetY: number = 0.5) => {
  const aFrame = useRef<any>(null);

  useEffect(() => {
    const render = () => {
      const main = mainRef.current;
      if (!!main) {
        const rect = main.getBoundingClientRect();

        const vpY = 1 / (window.innerHeight + rect.height) * (rect.top + rect.height);
        const vpYoff = (vpY - offsetY)*2;
        const vpYoffClamp = utils.clamp(vpYoff, 0, 1);

        main.style.transform = `translate3d(0, ${percentageY*vpYoffClamp}%, 0)`
      }
      aFrame.current = requestAnimationFrame(render);
    }

    aFrame.current = requestAnimationFrame(render);
    return () => cancelAnimationFrame(aFrame.current);
  }, []);
}

export default useParallaxElement;