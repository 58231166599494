import Breadcrumb from "components/breadcrumb/Breadcrumb";
import Video from "components/video/Video";
import WatchesSlider from "components/watchesSlider/WatchesSlider";
import React from "react";
import * as utils from "utilities/rocani";

import "./watches.scss";

interface Props {
  dataset: {
    breadcrumb: any;
    watchesHeroVideo: any;
  };
}

const Watches: React.FC<Props> = props => {
  const sliderData = utils.getDataArrayWithKey(props.dataset, "watchSlider_");

  return (
    <section className="iwc-watches iwc-breadcrumb__parent">
      <Breadcrumb data={props.dataset.breadcrumb} />
      <Video data={props.dataset.watchesHeroVideo} className="iwc-watches-hero" earlyPlay />
      <WatchesSlider data={sliderData} />
    </section>
  );
};

export default Watches;
