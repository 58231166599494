import React, { useEffect, useRef } from "react";
import Logo from "assets/iwc-logo.svg";
import RotatorG from "assets/rotator.svg";
import Claim from "assets/big-pilot-roadshow.svg";
import * as utils from "utilities/rocani";
import { customScrollY } from "utilities/customScroll/customScroll";

import "./rotator.scss";
import classNames from "classnames";

interface Props {
  json?: string;
  autoRotate?: boolean;
}

const Rotator: React.FC<Props> = props => {
  const aFrame = useRef<any>();

  const scrollStepY = useRef<number>(0);

  const rotation = useRef<number>(0);
  const stepTarget = useRef<number>(0);
  const stepIs = useRef<number>(1);

  const outerCircleRef = useRef<HTMLDivElement>(null);

  const data = !!props.json ? JSON.parse(props.json) : null;

  useEffect(() => {
    const handleRender = () => {
      const scrollY = customScrollY();
      if (scrollStepY.current === 0) {
        scrollStepY.current = scrollY;
      } else {
        stepTarget.current = Math.abs(scrollY - scrollStepY.current) / 10;
        stepTarget.current = utils.clamp(stepTarget.current, 0.05, 4);

        stepIs.current =
          stepIs.current + (stepTarget.current - stepIs.current) / 10;

        rotation.current += stepIs.current;

        scrollStepY.current = scrollY;

        const outerCircle = outerCircleRef.current;
        if (!!outerCircle) {
          outerCircle.style.setProperty("--rotate", `${rotation.current.toFixed(5)}deg`);
        }
      }

      aFrame.current = requestAnimationFrame(handleRender);
    };
    !props.autoRotate && (aFrame.current = requestAnimationFrame(handleRender));
    return () => {
      cancelAnimationFrame(aFrame.current);
    };
  }, []);

  return (
    <div className={classNames("iwc-rotator", {"is-autorotate": props.autoRotate })}>
      <div className="iwc-rotator__inner">
        <div className="iwc-rotator-circle" ref={outerCircleRef}>
          <RotatorG className="iwc-rotator-circle__outer" />
        </div>
        <div className="iwc-rotator-content">
          <Logo className="iwc-rotator-content__logo" />
          {!!data && !!data.title && (
            <h4
              className="iwc-rotator-content__title"
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
          )}
          {!!data && !!data.cta && !!data.link && (
            <a
              href={data.link}
              className="iwc-rotator-content__cta"
              target="_blank"
            >
              {data.cta}
            </a>
          )}
          {!!props.autoRotate && <Claim className="iwc-rotator-content__claim" />}
        </div>
      </div>
    </div>
  );
};

export default Rotator;
