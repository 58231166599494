import React from "react";

import "./credits.scss";

interface Props {}

const Credits: React.FC<Props> = props => {
  return (
    <div className="credits">
      <div className="credits-wrapper">
        <ul className="credits__list">
          <li>
            <span>Senior Art Director Digital:</span> Luca Banchelli
          </li>
          <li>
            <span>Senior Product Manager:</span> Shaun Rauch
          </li>
          <li>
            <span>Creative Development:</span> Studio Rocani
          </li>
          <li>
            <span>Interactive Developer:</span> Xavier Molina
          </li>
          <li>
            <span>Senior 3D Artist:</span> Tobias Raschbacher
          </li>
          <li>
            <span>Project Manager:</span> Jessa Butler
          </li>
          <li>
            <span>Branded Content Editor:</span> Caroline Brown
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Credits;
